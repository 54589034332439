<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/welcome' }" v-if="$_isAdmin()">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/dashboard' }" v-else>首页</el-breadcrumb-item>
            
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item>菜单管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card class="box-card">
            <div class="text item">
            <el-row :gutter="20">
                <!--遗留bug 第二页 搜索不好使 ，新增一个查询方法，将当前页码设置为1 再调用getUserList-->
                <!-- <el-col :span="8">
                    
                    <el-input placeholder="请输入菜单名称" v-model="queryInfo.name" clearable @clear="getRightsList">
                        <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </el-col> -->
                <el-col :span="4">
                    <el-button type="primary" v-show="$_has('menus:add')" @click="showAddDialog()">添加菜单</el-button>
                </el-col>
            </el-row>

            <!-- default-expand-all -->
            <el-table 
                :data="rightsList" 
                style="width: 100%" 
                border 
                stripe    
                ref="multipleTable" 
                
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                :row-key="getRowKeys"
                :expand-row-keys="expands"
                @expand-change="expandSelect"
                >
                <!--展开列-->
                <!-- <el-table-column type="expand">
                    <template slot-scope="scope">
                        <el-row v-for="(item1,i1) in scope.row.children" :key="item1.id">
                            
                            <el-col :span="5">
                                <el-tag>{{item1.authName}}</el-tag>
                            </el-col>
                            <el-col :span="19"></el-col>
                        </el-row>
                        <pre>{{scope.row}}</pre>
                    </template>
                </el-table-column> -->
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column prop="name" label="名称" ></el-table-column>
                <el-table-column prop="menuType" label="类型">
                    <template v-slot="scope">
                        <el-tag v-if="scope.row.menuType === 0">一级菜单</el-tag>
                        <el-tag v-else-if="scope.row.menuType === 1">子菜单</el-tag>
                        <el-tag v-else>按钮</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="component" label="组件" ></el-table-column>
                <el-table-column prop="perms" label="权限" ></el-table-column>
                <el-table-column prop="icon" label="图标" ></el-table-column>
                <el-table-column prop="url" label="路径" ></el-table-column>
                <el-table-column prop="sortNo" label="排序" ></el-table-column>
                
                <el-table-column label="操作" width="200px" header-align="center">
                    <template v-slot="scope">
                        <!--修改-->
                        <el-button type="primary" v-show="$_has('menus:edit')" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">编辑</el-button>
                        <!--删除-->
                        <el-button type="danger" v-show="$_has('menus:detele')" icon="el-icon-delete" size="mini" @click="removeRightsById(scope.row.id)">删除</el-button>
                      
                        
                    </template>
                </el-table-column>
            </el-table>

            
        </div>
        </el-card>

        <!--添加用户的对话框 ；async是vue1 现在vue2改为v-model
        :close-on-click-modal="false" 点击空白处不关闭对话框-->
        <el-dialog
            :title="this.addOReditDialogVisible == 0 ? '添加菜单' : '修改菜单'"
            :visible.sync="addDialogVisible"
            width="50%"
            @close="addDialogClosed"
            :close-on-click-modal="false">


            <!--主题内容区-->
            <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="80px">
                

                   
                <el-form-item label="节点类型" prop="menuType" >
                    <el-radio-group v-model="addForm.menuType">
                        <el-radio :label="0" :value="0">一级菜单</el-radio>
                        <el-radio :label="1" :value="1">子菜单</el-radio>
                        <el-radio :label="2" :value="2">按钮</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="节点名称" prop="name">
                    <el-input v-model="addForm.name"></el-input>
                </el-form-item>
                <el-form-item 
                    v-show="addForm.menuType!=0"
                    label="上级菜单"
                    prop="parentId">
                    <select-tree v-model="addForm.parentId" :options="rightsList" :props="defaultProps" />
                </el-form-item>
                <el-form-item label="路径" prop="url" v-show="addForm.menuType!=2">
                    <el-input v-model="addForm.url"></el-input>
                </el-form-item>
                <el-form-item label="前端组件" prop="component" v-show="addForm.menuType!=2">
                    <el-input v-model="addForm.component"></el-input>
                </el-form-item >
                <el-form-item label="权限" prop="perms" v-show="addForm.menuType==2">
                    <el-input v-model="addForm.perms"></el-input>
                </el-form-item>
                <el-form-item label="菜单图标" prop="icon" v-show="addForm.menuType==0">
                    <el-input v-model="addForm.icon"></el-input>
                </el-form-item>
                <el-form-item label="排序" prop="sortNo" v-show="addForm.menuType!=2">
                    <el-input v-model="addForm.sortNo"></el-input>
                </el-form-item>
                <!--该表status为string类型 所以去掉：-->
                <!-- <el-form-item label="是否启用" prop="status">
                    <el-switch 
                            v-model="addForm.status"
                            active-value="1"
                            inactive-value="2"
                            >
                    </el-switch>
                </el-form-item> -->
                <el-form-item label="备注说明" prop="remarks">
                    <el-input type="textarea" v-model="addForm.remarks"></el-input>
                </el-form-item>
                
                <el-form-item label="id" prop="id" v-show="false" hidden="true">
                    {{addForm.id}}
                </el-form-item>
            </el-form>
            <!--底部区-->
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取 消</el-button>
                <el-button type="primary" v-if="this.addOReditDialogVisible == 0" @click="addRights">确 定</el-button>
                <el-button type="primary" v-else-if="this.addOReditDialogVisible == 1" @click="editRights">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import SelectTree from '../widget/SelectTree.vue'
export default {
    components: {
     SelectTree,
    },

    data(){
        return{

            //自定义组件树形下拉框
            // 默认选中值
           //selected: 'A',
           // 数据默认字段
           defaultProps: {
             parentId: 'parentId',   // 父级唯一标识
             value: 'id',          // 唯一标识
             label: 'name',       // 标签显示
             children: 'children', // 子级
           },
           


            queryInfo:{
                
                //pageNo:0,//当前页数
                //pageSize:10//当前每页显示多少条
            },
            rightsList:[],
            //total:0, //共多少条数据

            // 要展开的行，数值的元素是row的key值
            //expands: ['540a2936940846cb98114ffb0d145cb8','717f6bee46f44a3897eca9abd6e2ec44'],
            expands: [],

            addDialogVisible:false,//控制对话框的显示与隐藏
            addForm:{
                id:'',
                menuType:0,
                name:'',
                url:'',
                icon:'',
                component:'',
                perms:'',
                sortNo:'',
                status:1,
                remarks:'',
                parentId:''
            },//添加用户的表单数据
            addFormRules:{//验证规则
                menuType:[
                    {required:true,message:'请选择节点类型',trigger:'blur'},
                ],
                name:[
                    {required:true,message:'请输入节点名称',trigger:'blur'},
                ],
                // url:[
                //     {required:true,message:'请输入跳转路径',trigger:'blur'},
                // ],
                // component:[
                //     {required:true,message:'请输入前端组件',trigger:'blur'},
                // ],
                // status:[
                //     {required:true,message:'请选择是否开启菜单',trigger:'blur'},
                // ],
            },
            addFormRef:{},
            
            addOReditDialogVisible:0, //0是add，1是edit
       }
    },
    created(){
        this.getRightsList();
    },
    methods:{
        async getRightsList(){
            const {data:res} = await this.$http.get('sys/permission/list');
            console.log(res);
            if(res.code !== 200){
                return this.$message.error('获取菜单列表失败');
            }
            
            this.rightsList = res.result;
        },
        getRowKeys(row){
            return row.id;
        },
        // 折叠面板每次只能展开一行
        expandChange2(row){
            console.log("进来的时候"+this.expands);
            // if (this.expands.indexOf(row.id) < 0) {
            //     this.expands = []
            //     this.expands.push(row.id);
            // } else {
            //     this.expands.remove(row.id);
            // }

            
            let $table = this.$refs.multipleTable;
           this.expands.map((item) => {
             if (row.id != item.id) {
               //$table.toggleRowExpansion(row, false)
               this.expands=[];
               console.log("清空")
             }
           })
           //$table.toggleRowExpansion(row)

           //$table.toggleRowExpansion(row, true)
           this.expands.push(row.id);

           //$table.toggleRowExpansion(oldcurrentRow, false)
           console.log("出去的时候"+this.expands);
        },

        // 折叠面板每次只能展开一行
      expandSelect(row, expandedRows) {
        // console.log('expandedRows', expandedRows)
        // console.log('row', row)
        var that = this
        if (expandedRows.length) {
          that.expands = []
          if (row) {
            that.expands.push(row.id)// 每次push进去的是每行的ID
          }
        } else {
          that.expands = []// 默认不展开
        }
        // console.log('that.expands', that.expands)
      },

    //   rowclick(row, event, column) {
    //         array.prototype.remove = function (val) {
    //         let index = this.indexof(val);
    //         if (index > -1) {
    //         this.splice(index, 1);
    //         }
    //         };
        
    //         if (this.expands.indexof(row.id) < 0) {
    //         this.expands.push(row.id);
    //         } else {
    //         this.expands.remove(row.id);
    //         }
        
    //     }

        //显示添加用户的对话框
        async showAddDialog(){
            const {data:res} = await this.$http.get('sys/user/queryUserRoleName?userid='+JSON.parse(window.sessionStorage.getItem('userInfo')).id);
            console.log(res);
            if(res.code !== 200){
                return this.$message.error('获取角色失败');
            }
            console.log(res.result);
            this.addForm.roles = res.result
            this.addDialogVisible = true
        },

        //监听用户添加Dialog关闭事件
        addDialogClosed(){
            this.$refs.addFormRef.resetFields()
            this.addOReditDialogVisible=0
        },
        addRights(){
             this.$refs.addFormRef.validate(async valid=>{
                 console.log(valid);
                 if(!valid) return
                 //可以发起网络请求
                 const {data:res} = await this.$http.post('sys/permission/add',this.addForm);
                 if(res.code !== 201) {
                     //少一个return？
                     return this.$message.error('添加菜单失败');
                 } //缺少判断菜单名是否重复
                
                 this.$message.success('添加菜单成功');
                 this.addDialogVisible = false;//关闭添加窗口
                 this.getRightsList();//从新获取列表数据
             })
        },


        //显示编辑用户的对话框
        async showEditDialog(id){
            console.log(id);
            this.addOReditDialogVisible=1;
            const {data:res} = await this.$http.get('sys/permission/queryById/?id='+id);
            console.log(res);
            if(res.code !== 200){
                return this.$message.error('查询菜单失败');
            }
            //问题的本质是因为你编辑时，第一次打开dialog的时候给表单绑定的form赋值了，这时候这个form的初始值就变成了你所赋值的值，所以resetFields的时候，会将form对应的每个值重置到初始值，这时候的初始值就是你编辑时赋值的那个值, 而不是在data里声明的初始值， 
            //解决方式是，等dialog已经初始化之后再给form赋值，也就是
            this.addDialogVisible = true
            this.$nextTick(() => { 
                this.addForm = res.result
            })
        },

        editRights(){
             this.$refs.addFormRef.validate(async valid=>{
                 console.log(valid);
                 if(!valid) return
                 //可以发起网络请求
                 const {data:res} = await this.$http.put('sys/permission/edit',this.addForm);
                 if(res.code !== 201) {
                     //少一个return？
                     return this.$message.error('修改菜单失败');
                 } //缺少判断菜单名是否重复
                
                 this.$message.success('修改菜单成功');
                 this.addDialogVisible = false;//关闭添加窗口
                 this.getRightsList();//从新获取列表数据
             })
        },

        //删除     
        //promis用于异步操作，asyne／await语法糖将异步操作的同步写法 根then用法是一样的 还避免了回调地狱
        //将同步写异步代码，代码会采用非阻塞的方式继续执行下去，解决回调地狱的问题
        async removeRightsById(id){
            //询问是否删除
            const confirmResult = await this.$confirm('此操作将永久删除该菜单, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            //如果用户确认删除，返回字符串confirm，如果用户点击取消，则返回值魏字符串cancel
            console.log(confirmResult)
            if(confirmResult !== 'confirm'){
                return this.$message.info('已经取消删除');
            }
            console.log('确认了删除');
            const {data:res} = await this.$http.delete('sys/permission/delete?id='+id);
            if(res.code !== 200) {
                //少一个return？
                return this.$message.error('删除菜单失败');
            } 
            this.$message.success('删除菜单成功');
            console.log("删除菜单成功");
            this.getRightsList();//重新获取菜单列表数据
            console.log("刷新列表");
        },
        
    }
}
</script>

<style lang="less" scoped>

</style>